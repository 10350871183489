import React, { useState, useEffect } from 'react';
import './Projects.css';
import peisemauringstop from '../Assets/hedderPeiseFont.png';
import fagdrill from '../Assets/fd_top.png';

const projectPic = [peisemauringstop, fagdrill, , ];

function Projects() {
  const [isMobile, setIsMobile] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [otherIndices, setOtherIndices] = useState([]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Adjust the breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize); // Listen for resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, []);

  useEffect(() => {
    // Update other indices when selectedItem changes
    if (selectedItem !== null) {
      setOtherIndices((prevIndices) => prevIndices.filter((index) => index !== selectedItem));
    }
  }, [selectedItem]);

  useEffect(() => {
    console.log('arrayAfter', otherIndices);
  }, [otherIndices]);

  const handleItemClick = (index) => {
    setSelectedItem(index);
    showProject(index);
  };

  const projectNames = ["Peise Maurings", "Fag Drill", "Spontaneity Prompt", "This page"];
  const projectDescription = ["Peise Maurings er en drikke app der brukere lager og spiller andre bruker lagde leker", "Fag Drill ble lagd sammen med Alta PP tjenesten. Som en sosial og øvings app til sertefisering.", "Spontaneity Prompt gir brukeren en enkel måte å være spontan i hverdagen.", "This page was made to show my resume and skils in webpage development."]

  const showProject = (index) => {
    const squereElement = document.querySelector('.squere');
    const squerePhone = document.querySelector('.squere_phone');
    const squereTwoElement = document.querySelector('.squereTwo');
    
    if (isMobile) {
      if (squerePhone) {
        squerePhone.classList.add('hiddenFade');
      }
    
      if (squereTwoElement) {
        setTimeout(() => {
          squerePhone.style.display = 'none';
          squereTwoElement.classList.remove('squereTwo');
          squereTwoElement.classList.add('squereTwoShow');
          const hiddenElements = document.querySelectorAll('.bigBox');
          hiddenElements.forEach((el) => {
            if (el.classList.contains('bigBox')) {
              el.classList.remove('bigBox');
              el.classList.add('show0');
            } 
          });
        }, 200); // Adjust the delay time as needed (in milliseconds)
      }
    } else {
      if (squereElement) {
        squereElement.classList.add('hiddenFade');
      }
    
      if (squereTwoElement) {
        setTimeout(() => {
          squereElement.style.display = 'none';
          squereTwoElement.classList.remove('squereTwo');
          squereTwoElement.classList.add('squereTwoShow');
          const hiddenElements = document.querySelectorAll(`.bigBox`);
          hiddenElements.forEach((el) => {
            if (el.classList.contains(`bigBox`)) {
              el.classList.remove(`bigBox`);
              el.classList.add('show0');
            } 
          });
        }, 250); // Adjust the delay time as needed (in milliseconds)
      }
    }
  };


  return (
    <div className="home-container_pro">
      <h3 className="nextTop_text_pro">My projects</h3>

      {isMobile ? (
        <div className="squere_phone">
          {/* Render project boxes */}
          {projectNames.map((project, index) => (
            <div className="education_box_pro" key={index} onClick={() => handleItemClick(index)}>
              {selectedItem !== index && (
                <>
                  <img src={projectPic[index]} alt="projectImg" className="pic_pm_icon_pro" />
                  <p className="mid_Text_pro">{projectDescription[index]}</p>
                </>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="squere">
        {/* Render project boxes */}
        {projectNames.map((project, index) => {
          return (
            <div className={`squere_box1`} key={index} onClick={() => handleItemClick(index)}>
              {selectedItem !== index && (
                <>
                  <img src={projectPic[index]} alt="projectImg" className="pic_pm_icon_pro" />
                  <p className="mid_Text_pro">{projectDescription[index]}</p>
                </>
              )}
            </div>
          );
        })}
      </div>
      )}

      <div className="squereTwo">
        <div className={`bigBox`}>
          <p className="education_Text">
            {selectedItem !== null && projectNames[selectedItem]}
          </p>
          <p className="mid_Text_pro">
            {projectDescription[selectedItem]}
          </p>
        </div>

      </div>
    </div>
  );
}

export default Projects;
