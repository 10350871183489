import ReactDOM from "react-dom";
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Screens/Home";
import Projects from "./Screens/Projects";
import Education from "./Screens/Education";
import Layout from "./Screens/Layout";
import WorkExperience from "./Screens/WorkExperience.js"

const rootElement = document.getElementById('root');

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Adjust the breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize); // Listen for resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout isMobile={isMobile} />}>
          <Route index element={<Home />} />
          <Route path="/education" element={<Education isMobile={isMobile} />} />
          <Route path="/workExperience" element={<WorkExperience />} />
          <Route path="/projects" element={<Projects />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <App />,
  rootElement
);
