import { Outlet, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './Home.css'; // Import CSS file with styles
import bitmoji from '../Assets/meg_bitmoji.png';

const Layout = ({isMobile }) => {

  // Define showmenu function in the component's outer scope
  const showmenu = () => {
    const sidebar = document.querySelector('.menu_bar');
    if (sidebar) {
      sidebar.style.display = 'flex';
    } else {
      console.error('Element with class "menu_bar" not found');
    }
  };
  const hidemenu = () => {
    const sidebar = document.querySelector('.menu_bar');
    if (sidebar) {
      sidebar.style.display = 'none';
    } else {
      console.error('Element with class "menu_bar" not found');
    }
  };

  const goToall = (site) => {
    window.location.href = site;
  };
  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <div>
      {isMobile ? (
        <div>

          
        <Outlet />
      </div>
    ) : (
      <div>  
        <nav style={{ display: 'none'}}>
          <ul style={{position:"absolute",listStyleType: 'none', margin: 0, display: 'flex',width:'10%',justifyContent: 'center',alignItems: 'center', alignSelf: 'center',}}>
            <img src={bitmoji} alt="projectImg" style={{width:'75px',justifySelf: 'center',paddingRight: '5px'}} />
              <li>
                  <Link to="/" style={{ color: "white", textDecoration: 'none', fontSize:20 }}>
                  Markus
                  </Link> {/* Remove underline */}
              </li>
          </ul>
          <ul style={{ listStyleType: 'none', padding: 36, margin: '0 auto', display: 'flex', width: '30%', justifyContent: 'center', alignSelf: 'center'}}>
            
            <li>
              <Link to="/education" style={{ color: '#999999', textDecoration: 'none', fontSize:15 }}>Education</Link> {/* Remove underline */}
            </li>
            <li style={{paddingLeft: '10%',paddingRight: '10%'}}>
              <Link to="/workExperience" style={{ color: '#999999', textDecoration: 'none', fontSize:15 }}>Work experience</Link> {/* Remove underline */}
            </li>
            <li>
              <Link to="/projects" style={{ color: '#999999', textDecoration: 'none', fontSize:15 }}>Projects</Link> {/* Remove underline */}
            </li>
          </ul>
          <ul style={{position:"absolute", right: 1,listStyleType: 'none', padding: 35, margin: 0, display: 'flex',width:'10%',justifyContent: 'right'}}>
              <li>
                  <Link to="/contact" style={{ color: "white", textDecoration: 'none', fontSize:20 }}>
                  Contact
                  </Link> {/* Remove underline */}
              </li>
          </ul>
        </nav>
        <Outlet />
      </div>
    )}
  </div>
  )
};

export default Layout;