import React, { useState } from 'react';
import "./Education.css"; // Import CSS file with styles

const Education = ({isMobile}) => {
  const [selectedItem, setSelectedItem] = useState(0);

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  return (
  <div>
    {isMobile ? (
      <div className="home-container_edu">
        <h3 className="nextTop_text_edu">My education</h3>

        <div className="row">
          <ul className="column left">
            <li onClick={() => handleItemClick(0)}>Dataingeniør<br />2020-2024</li>
            <li onClick={() => handleItemClick(1)}>Lærling<br />2017-2019</li>
            <li onClick={() => handleItemClick(2)}>IKT VG2<br />2016-2017</li>
            <li onClick={() => handleItemClick(3)}>Elektrofag VG1<br />2015-2016</li>
          </ul>
          <ul className="column right">
            <p className="mid_Text_edu">
              {selectedItem === 0 && (
                <>
                  Dataingeniør ved Universitetet i agder<br /><br />Med fagbrev som bakgrunn valgte jeg “Y-veien” til dataingeniør og denne er normert til tre år. Jeg har valgt softwareutvikling som spesialisering og har hovedsaklig jobbet med fullstack-utvikling av nettsider og apper. Som bacheloroppgave laget vi en sertifiseringsapp til Alta kommune sin PP-tjeneste. På denne oppgaven fikk gruppen vår karakteren C. På grunn av sykdom og dødsfall i familien, utsatte jeg noen fag vinteren 2021/2022 og blir ferdig våren 2024.
                </>
              )}
              {selectedItem === 1 && (
                <>
                  Lærling hos Fylkesmannen i Finnmark <br /><br />
                  Jeg var med på omorganisering og sammenslåing av to embeter (Troms og Finnmark). Vi jobbet mye med å få på plass felles rutiner. 
                  1.1.2019 ble alle IT- og arkivavdelinger i landet samlet og plassert i den nye organisasjonen Fylkesmennenes felles administrasjon (FMFA).
                </>
              )}
              {selectedItem === 2 && (
                <>
                  IKT VG2 ved Vardø videregående skole<br /><br />
                  Snittkarakter 4,5 og 6 på tverrfaglig eksamen.
                </>
              )}
            </p>
          </ul>
        </div>
      </div>
      ) : (
        <div className="home-container_edu">
          <h3 className="nextTop_text_edu">My education</h3>

          <div className="row">
            <ul className="column left">
              <li onClick={() => handleItemClick(0)}>Dataingeniør<br />Universitetet i agder<br />2020-2024</li>
              <li onClick={() => handleItemClick(1)}>Lærling<br />Fylkesmannen i Finnmark<br />2017-2019</li>
              <li onClick={() => handleItemClick(2)}>IKT VG2<br />Vardø videregående skole<br />2016-2017</li>
              <li onClick={() => handleItemClick(3)}>Elektrofag VG1<br />Alta videregående skole<br />2015-2016</li>
            </ul>
            <ul className="column right">
              <p className="mid_Text_edu">
                {selectedItem === 0 && (
                  <>
                    Dataingeniør ved Universitetet i agder<br /><br />Med fagbrev som bakgrunn valgte jeg “Y-veien” til dataingeniør og denne er normert til tre år. Jeg har valgt softwareutvikling som spesialisering og har hovedsaklig jobbet med fullstack-utvikling av nettsider og apper. Som bacheloroppgave laget vi en sertifiseringsapp til Alta kommune sin PP-tjeneste. På denne oppgaven fikk gruppen vår karakteren C. På grunn av sykdom og dødsfall i familien, utsatte jeg noen fag vinteren 2021/2022 og blir ferdig våren 2024.
                  </>
                )}
                {selectedItem === 1 && (
                  <>
                    Lærling hos Fylkesmannen i Finnmark <br /><br />
                    Jeg var med på omorganisering og sammenslåing av to embeter (Troms og Finnmark). Vi jobbet mye med å få på plass felles rutiner. 
                    1.1.2019 ble alle IT- og arkivavdelinger i landet samlet og plassert i den nye organisasjonen Fylkesmennenes felles administrasjon (FMFA).
                  </>
                )}
                {selectedItem === 2 && (
                  <>
                    IKT VG2 ved Vardø videregående skole<br /><br />
                    Snittkarakter 4,5 og 6 på tverrfaglig eksamen.
                  </>
                )}
              </p>
            </ul>
          </div>
        </div>
      )}
  </div>
  );
}

export default Education;
