// Contact.js
import React from 'react';
import "./Work_experience.css"

function WorkExperience() {
  return (
    <div className="home-container_work">
      <h3 className="nextTop_text_work">Work experience</h3>
      <div className="education_box_work">
        <p className="education_Text">
        Fylkesmennenes felles administrasjon (FMFA) - Fagarbeider med fagbrev 2019-2020
        </p>
        <p className="mid_Text_work">
        Etter bestått fagprøve med karakter MG fikk jeg jobb hos FMFA, der jeg jobbet med brukerstøtte via Zendesk. Jeg var på lokasjonen i Vadsø og var med på felles oppfølging av lokasjoner i hele Norge. Jeg hadde også oppgaver knyttet til servere og nettverk i Vadsø og ved resten av lokasjonene i Finnmark.
        </p>
      </div>  
    </div>
  );
}

export default WorkExperience;
