// Home.js
import React, { useEffect, useState } from 'react';
import markusImage from '../Assets/Pic_me.png';
import markusNoBG from '../Assets/meg-removebg.png';
import './Home.css'; // Import CSS file with styles
import linkedinIcon from '../Assets/linkedin-icon.png'; // Import LinkedIn icon image
import facebookIcon from '../Assets/facebook_pic.png'; // Import Facebook icon image
import { Outlet, Link } from "react-router-dom";
import peisemauringstop from "../Assets/hedderPeiseFont.png"
import edu_1_pic from'../Assets/edu_1.png';
import edu_2_pic from'../Assets/edu_2.png';
import edu_3_pic from'../Assets/edu_3.png';
import edu_4_pic from'../Assets/edu_4.png';
import fagdrill from '../Assets/fd_top.png';
import fmfi from '../Assets/fmfi.png';
import bitmoji from '../Assets/meg_bitmoji.png';
import pm_icon from '../Assets/pm_icon_card.png';
import fd_icon from '../Assets/fd_icon.png';
import top_bg from '../Assets/top_bg.png';
import top2_bg from '../Assets/top2_bg.png';
import pm_ss from '../Assets/pm_frontPage.png';
import fd_ss from '../Assets/fd_ss.png';
import mePicture from '../Assets/envelope greeting.png';
import pmFramework from '../Assets/pmFramework.png'
import portfolio_pic from '../Assets/portfolio_card_img.PNG'
import portfolioFramework from '../Assets/FD_framework.png'
import fdFrameWork from '../Assets/fdFrame.png'
import SP_topPic from '../Assets/SP_topPic.png'
import spicon from '../Assets/SPicon.png'
import sp_cardPic from '../Assets/SPCardPic.png'
import playPic from '../Assets/PlayBtn.png'
import sp_ss from '../Assets/sp_ss.png'
import portfolio_top from '../Assets/Portfolio_top.png'
import missingPic from '../Assets/missing.png'
import ms_icon from '../Assets/MS_icon1.png'
import ms_top from '../Assets/PromografikkMS.png'
import ms_ss from '../Assets/MS_g_b.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';

const projectPic = [peisemauringstop, fagdrill,sp_cardPic ,portfolio_pic, ms_top];
const projectLink = [playPic, missingPic, missingPic, missingPic, missingPic];
const projecticon = [pm_icon, fd_icon, spicon, bitmoji,ms_icon];
const projectSS = [pm_ss, fd_ss, sp_ss,markusNoBG, ms_ss];
const projectTopBg = [top_bg, top2_bg, SP_topPic,portfolio_top, ms_top];
const projectFramework = [pmFramework, fdFrameWork, pmFramework, portfolioFramework, pmFramework];

function Home() {
  const [isMobile, setIsMobile] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemEdu, setSelectedItemEdu] = useState(0);
  const [otherIndices, setOtherIndices] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Adjust the breakpoint as needed
    };
  
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize); // Listen for resize events
  
    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, []); // Empty dependency array to run this effect only once after initial render

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show')
        } else {
          entry.target.classList.remove('show')
        }
      });
    });
  
    const hiddenElements = document.querySelectorAll('.hidden');
    hiddenElements.forEach((el) => observer.observe(el));

  }, []); 

  const rotateCards = (event) => {
    const card = event.currentTarget;
    const rect = card.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
  
    // Calculate offsetX and offsetY relative to the center of the card
    const offsetX = (centerX - event.clientX) / (rect.width / 2) * 35;
    const offsetY = (centerY - event.clientY) / (rect.height / 2) * 35;
  
    card.style.setProperty("--rotateX", `${(-1*offsetY)}deg`);
    card.style.setProperty("--rotateY", `${offsetX}deg`);
  };

  
  const resetRotation = (event) => {
    const card = event.currentTarget;
    card.style.removeProperty("--rotateX");
    card.style.removeProperty("--rotateY");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const scriptURL = 'https://script.google.com/macros/s/AKfycbybijL1Eb-9ehop2oOFkANOX1qdU_6F5ugxvi9ldji0lwJZTcAWjTG8kGCkDJnagfCB/exec';
    const formData = new FormData(e.target);
    fetch(scriptURL, { method: 'POST', body: formData })
      .then(response => {
        if (response.ok) {
          alert("Thank you! Your form was submitted successfully.");
          window.location.reload();
        } else if (response.result === 'success') {
          window.location.reload();
        } 
        else {
          throw new Error('Network response was not ok.');
        }
      })
      .catch(error => console.error('Error!', error.message));
  };

  useEffect(() => {
    const form = document.forms['contact-form'];
    if (form) {
      form.addEventListener('submit', handleSubmit);
    }
  }, []);
  
  useEffect(() => {
    const form = document.forms['contact-form-phone'];
    if (form) {
      form.addEventListener('submit', handleSubmit);
    }
  }, []);
  
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  let href; // Declare href outside the function

  const goToLink = (place) => {
    if (place === 'Peise Maurings') {
      href = 'https://play.google.com/store/apps/details?id=pm.drikapp';
      window.location.href = href;
    } else {
      alert("Link not active yet.");
    }
  };


  useEffect(() => {
    // Update other indices when selectedItem changes
    if (selectedItem !== null) {
      setOtherIndices((prevIndices) => prevIndices.filter((index) => index !== selectedItem));
    }
  }, [selectedItem]);

  useEffect(() => {
    console.log('arrayAfter', otherIndices);
  }, [otherIndices]);

  const handleItemClick = (index) => {
    setSelectedItem(index);
    showProject(index);
  };
  const handleItemClickEdu = (index) => {
    setSelectedItemEdu(index);
  };
  const handleUnCheckItem = () => {
    showProjectGrid();
  };
  const handleUnCheckItemPhone = () => {
    showProjectGrid();
  };

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleItemClickPone = (index) => {
    setSelectedItem(index);
    showProjectPhone(index);
    setTimeout(() => {
      const element = document.getElementById('projects');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 400);
    
  };

  const projectNames = ["Peise Maurings", "Fag Drill", "Spontaneity Prompt", "Portfolio website", "Minesweeper"];
  const projectDescription = ["Peise Maurings is a drinking app where users can play pre-made games, games created by others, or create their own games.", "Fag Drill was created in collaboration with the PP Service in Alta municipality as part of the bachelor's thesis. This is an app for social games and practice exercises for academic training.", "Spontaneity Prompt provides the user with an easy way to be spontaneous in everyday life.", "My portfolio website was made to show my resume and skils in webpage design and development.","I made Minesweeper just so I don't have ads when I play Minesweeper."]
  const projectCardDescription = ["Peise Maurings is a party app that makes it possible to liven up the pre party or the party. The packages we offer are just the start of using the app. You can create your own games. Therfor you always know that game you play suits the group.", "Fag Drill was created in collaboration with the PP Service in Alta municipality as part of the bachelor's thesis. This is an app for social games and practice exercises for academic training. Admins can create and share quizzes with users who need to see or practice that specific quiz.", "Embrace spontaneity and inject a dash of excitement into your life with our innovative app. Designed to ignite spontaneity in every aspect of your day, whether with friends or your partner, this app is your ultimate companion for seizing the moment.", "Here, you'll find a curated collection of my latest projects and creations, each a testament to my passion for innovation and creativity. From sleek web designs to captivating mobile applications, my portfolio showcases a diverse range of skills and expertise.","I made Minesweeper just so I don't have ads when I play Minesweeper. Simple and just what you need to play and keep your high score."]


  const showProject = (index) => {
    const squereElement = document.querySelector('.squere_home');
    const squereTwoElement = document.querySelector('.squereTwo_home');
    
    if (isMobile) {
      if (squereTwoElement) {
        setTimeout(() => {
          squereTwoElement.classList.remove('squereTwo_home');
          squereTwoElement.classList.add('squereTwoShow_home');
          const hiddenElements = document.querySelectorAll('.bigBox_home');
          hiddenElements.forEach((el) => {
            el.classList.remove('bigBox_home');
            el.classList.add('show0');
          });
        }, 200);
      }
    } else {
      if (squereElement) {
        squereElement.classList.add('hiddenFade');
        squereTwoElement.style.display = 'flex';
        setTimeout(() => {
          squereTwoElement.classList.remove('squereTwo_home');
          squereTwoElement.classList.add('squereTwoShow_home');
          const hiddenElements = document.querySelectorAll('.squereTwoCard_home');
          hiddenElements.forEach((el) => {
            if (el.classList.contains(`squereTwoCard_home`)) {
            el.classList.remove('squereTwoCard_home');
            el.classList.add('show_big_box');
          } 
          });
        }, 250);
      }
    }
  };

  
  const showProjectGrid = () => {
    const squereElement = document.querySelector('.squere_home');
    const squereTwoElement = document.querySelector('.squereTwoShow_home');
    
    const squereElementPhone = document.querySelector('.squere_phone');
    const squereTwoElementPhone = document.querySelector('.squereTwoShow');
    
    if (isMobile) {
      if (squereTwoElementPhone) {
        squereTwoElementPhone.classList.remove('squereTwoShow');
        squereTwoElementPhone.classList.add('squereTwo');
        
        setTimeout(() => {
          squereElementPhone.style.display = 'block';
          squereElementPhone.classList.remove('hiddenFade');
          const hiddenElements = document.querySelectorAll('.show0');
          hiddenElements.forEach((el) => {
            if (el.classList.contains('show0')) {
              el.classList.remove('show0');
              el.classList.add('bigBox');
            } 
          });
          setSelectedItem(null);
        }, 250);
      }
      } else {
      if (squereTwoElement) {

        squereTwoElement.classList.remove('squereTwoShow_home');
        squereTwoElement.classList.add('squereTwo_home');
        
        setTimeout(() => {
          squereTwoElement.style.display = 'none';
          squereElement.style.display = 'grid';
          squereElement.classList.remove('hiddenFade');
          squereElement.classList.add('squere_home');
          setSelectedItem(null);
        }, 250);
      }
    }
  };

  const showProjectPhone = (index) => {
    const squerePhone = document.querySelector('.squere_phone');
    const squereTwoElement = document.querySelector('.squereTwo');
    
    if (isMobile) {
      if (squerePhone) {
        squerePhone.classList.add('hiddenFade');
      }
    
      if (squereTwoElement) {
        setTimeout(() => {
          squerePhone.style.display = 'none';
          squereTwoElement.classList.remove('squereTwo');
          squereTwoElement.classList.add('squereTwoShow');
          const hiddenElements = document.querySelectorAll('.bigBox');
          hiddenElements.forEach((el) => {
            if (el.classList.contains('bigBox')) {
              el.classList.remove('bigBox');
              el.classList.add('show0');
            } 
          });
        }, 200); // Adjust the delay time as needed (in milliseconds)
      }
    } 
  };



  const showmenu = () => {
    const sidebar = document.querySelector('.menu_bar');
    if (sidebar) {
      sidebar.style.display = 'flex';
    } else {
      console.error('Element with class "menu_bar" not found');
    }
  };
  const hidemenu = () => {
    const sidebar = document.querySelector('.menu_bar');
    if (sidebar) {
      sidebar.style.display = 'none';
    } else {
      console.error('Element with class "menu_bar" not found');
    }
  };


  

  return (
    <div>
      {isMobile ? (
        <div className="home-container-phone">
          <div>
          <nav style={{ display: 'flex', position:"fixed", right: '5%',}}>
            <ul style={{ listStyleType: 'none', margin: 10, marginTop:18, width:'100%', paddingLeft: 0, paddingBottom: "5%" }}>
              <li onClick={showmenu}>
                <FontAwesomeIcon icon={faBars} style={{ color: "white", fontSize:20  }} />
              </li>
            </ul>
          </nav>
          <ul class="menu_bar">
            <li onClick={hidemenu}><FontAwesomeIcon icon={faBars} style={{ color: "white", fontSize:20 }} /></li>
            <li onClick={() => scrollToElement('me')}><a >Home</a></li>
            <li onClick={() => scrollToElement('education')}><a >Education</a></li>
            <li onClick={() => scrollToElement('experience')}><a >Work experience</a></li>
            <li onClick={() => scrollToElement('projects')}><a >Projects</a></li>
            <li onClick={() => scrollToElement('contact')}><a >Contact</a></li>
          </ul>
        <Outlet />
      </div>
            <div className="left-column_home">
              <div >
                <h2 className="top_text " id="me">Hi<br />I'm Markus</h2>
                <h3 className="nextTop_text">a full stack developer</h3>
              </div>
              <div >
                <p className="mid_Text ">
                  
I'm 24 years old and from the northern lights city of Alta. I have a apprenticeship certificate in ICT and worked extensively with user service and Active Directory during my apprenticeship. In 2020, I started my studies in computer engineering in Grimstad and will complete the program in the spring semester of 2024.
                </p>
                <img src={markusImage} alt="Markus Liland" className="picOfMe_phone"/>
              
              </div>
                <div className="buttons-container_phone">
                  <div class= "btn ">
                    <button className="contact-button" onClick={() => scrollToElement('contact')}>Contact</button>
                  </div>
                  <div class= "btn ">
                    <button className="learn-more-button" onClick={() => scrollToElement('education')}>Learn More</button>
                  </div>
                </div>
                <div className="sociale-container">
                  <div class= "sm ">
                    <a href="https://www.linkedin.com/in/markus-liland-3847b224b/" target="_blank" rel="noopener noreferrer" className="linkedin-button">
                      <img src={linkedinIcon} alt="LinkedIn" />
                    </a>
                  </div>
                  <div class= "sm ">
                    <a href="https://www.facebook.com/markus.liland/" target="_blank" rel="noopener noreferrer" className="facebook-button">
                      <img src={facebookIcon} alt="Facebook" />
                    </a>
                  </div>
                </div>
              <div class="">
                <h3 className="nextTop_text" id="education">My education</h3>
              </div>
              
            <div className="home-container_edu">
              <div className="row">
                <ul className="column left">
                  <li onClick={() => handleItemClickEdu(0)}>Computer engineer<br />2020-2024</li>
                  <li onClick={() => handleItemClickEdu(1)}>ICT Apprentice<br />2017-2019</li>
                  <li onClick={() => handleItemClickEdu(2)}>ICT VG2<br />2016-2017</li>
                  <li onClick={() => handleItemClickEdu(3)}>Electrician VG1<br />2015-2016</li>
                </ul>
                <ul className="column right">
                  <p className="mid_Text_edu">
                    {selectedItemEdu === 0 && (
                      <>
                        University of Agder<br /><br />With an apprentice certificate as my background, I chose the 'Y-path' to become a computer engineer, which is normally a three-year program. I specialized in software development and mainly worked on full-stack development of websites and apps. For my bachelor's thesis, my group developed a certification app for Alta municipality's PP service. Our group received a grade of C for this project. Due to illness and a family bereavement, I postponed some courses during the winter of 2021/2022 and will complete my studies in the spring of 2024.
                      </>
                    )}
                    {selectedItemEdu === 1 && (
                      <>
                        County Governor of Finnmark <br /><br />
                        I was involved in the reorganization and merger of two offices (Troms and Finnmark). We worked extensively to establish common routines. On January 1st, 2019, all IT and archive departments in the country were merged and placed under the new organization called the Joint Administration of the County Governors (FMFA).
                      </>
                    )}
                    {selectedItemEdu === 2 && (
                      <>
                        Vardø Upper Secondary School<br /><br />
                        This school year was much more interesting, sparking my interest in coding, and prompting me to begin self-directed learning.<br /><br />I achieved an average grade of 4.5 and 6 on the interdisciplinary exam.
                      </>
                    )}
                    {selectedItemEdu === 3 && (
                      <>
                        I wanted to pursue ICT, but I had to complete a VG1-year first, where I learned a lot of fundamental knowledge about electrical engineering, which is useful to have.<br /><br />Average grade of 4.
                      </>
                    )}
                  </p>
                </ul>
              </div>
            </div>
             
              <div class="">
                <h3 className="nextTop_text" id="experience">Work experience</h3>
              </div>
              <div class="">
                <div className="education_box">
                  <p className="education_Text">
                    The Joint Administration of the County Governors (FMFA) - ICT Worker with an Apprenticeship Certificate 2019-2020
                  </p>
                  <p className="education_Text">
                  After passing the Apprenticeship examination with a grade of well done (best grade), I got a job at FMFA, where I worked with user support via Zendesk. I was stationed in Vadsø and participated in the collective management of locations across Norway. I also had tasks related to servers and networks in Vadsø and at the other locations in Finnmark.
                  </p>
                </div>
              </div>
              <div class="">
                <h3 className="nextTop_text" id="projects">Projects</h3>
              </div>

              
                <div className="squere_phone">
                  {/* Render project boxes */}
                  {projectNames.map((project, index) => (
                    <div className="education_box_pro" key={index} onClick={() => handleItemClickPone(index)}>
                      {selectedItem !== index && (
                        <>
                          <img src={projectPic[index]} alt="projectImg" className="pic_pm_icon_pro" />
                          <p className="mid_Text_pro">{projectDescription[index]}</p>
                        </>
                      )}
                    </div>
                  ))}
              </div>
              <div className="squereTwo">
                <div className={`bigBox`}>
                  <div className="squereTwoCard_Top" style={{backgroundImage: `url(${projectTopBg[selectedItem]})`}}>
                    <div className="back_button" onClick={() => handleUnCheckItemPhone()}>
                    </div>
                    <img src={projecticon[selectedItem]} alt="icon" className="card_icon"/>
                    <img src={projectLink[selectedItem]} alt="projectImg" className="play_btn" onClick={() => goToLink(projectNames[selectedItem])}/>
                  </div>
                  <div className="squereTwoCard_midPhone">
                    <p className="project_top_textPhone">
                      {selectedItem !== null && projectNames[selectedItem]}
                    </p>
                    <p className="project_mid_textPhone">
                      {projectCardDescription[selectedItem]}
                    </p>
                  </div>
                  <div className="squereTwoCard_bottom_Phone" style={{backgroundImage: `url(${projectFramework[selectedItem]})`}}></div>
                </div>

              </div>
              <div class="">
                <h3 className="nextTop_text" id="contact">Contact me</h3>
              </div>
              <div class="">
                
                <form method="post" name='contact-form-phone' action='https://script.google.com/macros/s/AKfycbybijL1Eb-9ehop2oOFkANOX1qdU_6F5ugxvi9ldji0lwJZTcAWjTG8kGCkDJnagfCB/exec' style={{ backgroundColor: '#212121', height: '310px', width: '88vw', borderRadius: '5px', overflow: 'hidden', padding: '10px', boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.5)', marginBottom: '25px'}}>
                  <div style={{ marginBottom: '10px',marginTop: '10px', display: 'flex', justifyContent: 'space-between', backgroundColor: '#212121' }}>
                    <div style={{ flex: 1, marginRight: '10px' }}>
                      <label style={{ color: 'white' }}>Name:</label>
                      <input type="text" name="name" style={{ width: '90%', padding: '8px', borderRadius: '3px', border: '1px solid #212121', backgroundColor: '#333', color: 'white' }} />
                    </div>
                    <div style={{ flex: 1 }}>
                      <label style={{ color: 'white' }}>Email:</label>
                      <input type="text" name="email" style={{ width: '90%', padding: '8px', borderRadius: '3px', border: '1px solid #212121', backgroundColor: '#333', color: 'white' }} />
                    </div>
                  </div>
                  <p style={{ marginBottom: '10px' }}>
                    <label style={{ color: 'white' }}>Subject:</label>
                    <input type="text" name="subject" style={{ width: '95%', padding: '8px', borderRadius: '3px', border: '1px solid #212121', backgroundColor: '#333', color: 'white' }} />
                  </p>
                  <p style={{ marginBottom: '10px' }}>
                    <label style={{ color: 'white' }}>Message:</label>
                    <textarea name="message" rows="4" style={{ width: '95%', padding: '8px', borderRadius: '3px', border: '1px solid #212121', backgroundColor: '#333', color: 'white' }}></textarea>
                  </p>
                  <button type="submit" style={{ backgroundColor: '#4070F4', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Send Message</button>
                </form>
                
              </div>
              <div className="bottomBorder">
                <div className="bottomBorderBox">
                  <p className="bottom_Text" onClick={() => scrollToElement('me')}>About me</p>
                  <p className="bottom_Text" onClick={() => scrollToElement('experience')}>Work experience</p>
                </div>
                <div className="bottomBorderBox">
                  <p className="bottom_Text" onClick={() => scrollToElement('education')}>Education</p>
                  <p className="bottom_Text" onClick={() => scrollToElement('projects')}>Projects</p>
                </div>
                <div className="bottomBorder3">
                  <img src={bitmoji} alt="projectImg" className="bitmoji" />
                </div>
              </div>
            </div>
          </div>
      ) : (
        <div className="home-container" id="home">
            <nav style={{ display: 'flex',width:'100%'}}>
              <ul
              style={{
                position: 'fixed',
                top: isScrolled ? '6%' : '1%',
                left: isScrolled ? '50%' : '5%',
                transform: isScrolled ? 'translate(-50%, -50%)' : 'translateX(-50%)',
                paddingRight: isScrolled ? '40px' : '0px',
                listStyleType: 'none',
                margin: 0,
                display: 'flex',
                flexDirection: isScrolled ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 999,
                transition: 'top 0.3s, left 0.3s',
              }}
            >
              <img
                src={bitmoji}
                alt="projectImg"
                className="top_bitmoji"
                onClick={() => scrollToElement('home')}
              />

            </ul>
              <ul style={{ listStyleType: 'none', padding: 36, margin: '0 auto', display: 'flex', width: '40%', justifyContent: 'center', alignSelf: 'center'}}>
                <li>
                  <Link onClick={() => scrollToElement('about')} style={{ color: '#999999', textDecoration: 'none', fontSize:15 }}>About me</Link> {/* Remove underline */}
                </li>
                <li style={{paddingLeft: '10%'}}>
                  <Link onClick={() => scrollToElement('education')} style={{ color: '#999999', textDecoration: 'none', fontSize:15 }}>Education</Link> {/* Remove underline */}
                </li>
                <li style={{paddingLeft: '10%',paddingRight: '10%'}}>
                  <Link onClick={() => scrollToElement('work')} style={{ color: '#999999', textDecoration: 'none', fontSize:15 }}>Work experience</Link> {/* Remove underline */}
                </li>
                <li>
                  <Link onClick={() => scrollToElement('projects')} style={{ color: '#999999', textDecoration: 'none', fontSize:15 }}>Projects</Link> {/* Remove underline */}
                </li>

              </ul>
              <ul style={{position:"absolute", right: 1,listStyleType: 'none', padding: 35, margin: 0, display: 'flex',width:'10%',justifyContent: 'right'}}>
                  <li>
                      <Link onClick={() => scrollToElement('contact')} style={{ color: "white", textDecoration: 'none', fontSize:'20px', borderradius: '50px',background: 'linear-gradient(145deg, #0e1213, #111516)', boxshadow: '20px 20px 60px #0e1112,-20px -20px 60px #121718'}}>
                      Contact
                      </Link> {/* Remove underline */}
                  </li>
              </ul>
            </nav>
          <div className="p1" >
            <div className="left-column">
              <h2 className="top_text hidden">Hi<br />I'm Markus</h2>
              <h3 className="nextTop_text hidden">a full stack developer</h3>
              <p className="mid_Text hidden">
                Join me on a journey through the intersection of technology and design, where every project is an opportunity to push boundaries and inspire change. Let's create something extraordinary together.
              </p>
              <div className="buttons-container hidden">
                  <Link className="contact-button" onClick={() => scrollToElement('contact')}>Contact me</Link>
                  <button className="learn-more-button" onClick={() => scrollToElement('about')}>About me</button>
              </div>
              <div className="sociale-container hidden">
                <a href="https://www.linkedin.com/in/markus-liland-3847b224b/" target="_blank" rel="noopener noreferrer" className="linkedin-button">
                  <img src={linkedinIcon} alt="LinkedIn" />
                </a>
              </div>
            </div>
            <div className="right-column hidden">
              <img src={markusImage} alt="Markus Liland" className="picOfMe"/>
            </div>
          </div>
          <div className="p2" id="about">
            <div className="left-column hidden">
              <img src={markusNoBG} alt="Markus Liland" className="picOfMe"/>
            </div>
            <div className="right-column hidden">
              <h2 className="top_text">About me</h2>
              <p className="mid_Text">
                I am 24 years old and from the northern lights city of Alta. In my first year of high school, I studied to be a electrician at Alta Upper Secondary School. At the age of 16, I left home and started studying ICT at Vardø Upper Secondary School. I obtained an apprenticeship contract with the County Governor of Finnmark and lived in Vadsø for 2.5 years, where I completed my vocational training. As an apprentice and later as an employee, I worked extensively in user service, with servers, and was heavily involved in the merger of the County Governors' shared administration (FMFA). During the merger process, I had many tasks involving VMware and implementing new equipment for FMFA.
              </p>
            </div>
            <div className="bottom">
              <div className="left-column hidden">
                <p className="mid_Text">
                  I am independent in the execution of tasks and have excellent teamwork skills. I embrace challenges and strive to find solutions. I like the whole process of development, from figuring out a problem statement to implementing the solution. I have plenty of work capacity, and I am a very reliable and conscientious employee.
                </p>
              </div>
              <div className="right-column hidden">
                <p className="mid_Text">
                  In 2020, I started my studies in computer engineering in Grimstad. Both during and outside of my studies, I have worked on several side projects to develop my skills as a developer. One of these applications is available on the Google Play Store, and I am currently working on getting it released on the Apple Store.
                </p>
              </div>
            </div>
          </div>
          <div className="p3" id="education">
            <h2 className="top_text hidden">My education</h2>
            <div className="center-education hidden">
              <div className="educationCard">
                <img src={edu_1_pic} alt="Markus Liland" className="educationPic"/>
                <h2 className="education_card_text">Computer engineering<br />university of<br />Agder<br />2020-2024</h2>
                <p className="info_text">
                  With an apprentice certificate as my background, I chose the 'Y-path' to become a computer engineer, which is normally a three-year program. I specialized in software development and mainly worked on full-stack development of websites and apps. For my bachelor's thesis, my group developed a certification app for Alta municipality's PP service. Our group received a grade of C for this project. Due to illness and a family bereavement, I postponed some courses during the winter of 2021/2022 and will complete my studies in the spring of 2024.
                </p>
              </div>
              <div className="educationCard">
                <img src={edu_2_pic} alt="Markus Liland" className="educationPic"/>
                <h2 className="education_card_text">ICT Apprentice<br />County Governor of<br />Finnmark<br />2017-2019</h2>
                <p className="info_text">
                I was involved in the reorganization and merger of two offices (Troms and Finnmark). We worked extensively to establish common routines. On January 1st, 2019, all IT and archive departments in the country were merged and placed under the new organization called the Joint Administration of the County Governors (FMFA).
                </p>
              </div>
              <div className="educationCard">
                <img src={edu_3_pic} alt="Markus Liland" className="educationPic"/>
                <h2 className="education_card_text">ICT-VG2 Vardø<br />Upper Secondary<br />School<br />2016-2017</h2>
                <p className="info_text">
                This school year was much more interesting, sparking my interest in coding, and prompting me to begin self-directed learning. I achieved an average grade of 4.5 and 6 on the interdisciplinary exam.
                </p>
              </div>
              <div className="educationCard">
                <img src={edu_4_pic} alt="Markus Liland" className="educationPic"/>
                <h2 className="education_card_text">Electrician VG1<br />Alta Upper Secondary<br />School<br />2015-2016</h2>
                <p className="info_text">
                I wanted to pursue ICT, but I had to complete a VG1-year first, where I learned a lot of fundamental knowledge about electrical engineering, which is useful to have. Average grade of 4.
                </p>
              </div>
            </div>
          </div>
          <div className="p_4" id="work">
            <h2 className="top_text hidden">Work experience</h2>
            <div className="center-education hidden">
              <div className="workCard">
                <img src={fmfi} alt="fmfi" className="fmfiPic"/>
                <div className="workCard_Tbox">
                  <h2 className="workCard_text">The Joint Administration of the County Governors (FMFA) - ICT Worker with an Apprenticeship Certificate 2019-2020</h2>
                  <p className="workCard_mid_Text">
                  After passing the Apprenticeship examination with a grade of well done (best grade), I got a job at FMFA, where I worked with user support via Zendesk. I was stationed in Vadsø and participated in the collective management of locations across Norway. I also had tasks related to servers and networks in Vadsø and at the other locations in Finnmark.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="p4 hidden" id="projects"> <h2 className="top_text">My projects</h2>
            <div className="squere_home">
              {projectNames.map((project, index) => (
                <div
                  className={`squere_box1_home`}
                  id="projectCard"
                  key={index}
                  onClick={() => handleItemClick(index)}
                  onMouseMove={(e) => rotateCards(e)}
                  onMouseLeave={resetRotation}
                >
                  {selectedItem !== index && (
                    <>
                      <img src={projectPic[index]} alt="projectImg" className="pic_pm_icon_pro" />
                      <p className="mid_Text_pro">{projectDescription[index]}</p>
                    </>
                  )}
                </div>
              ))}
            </div>
            <div className="squereTwo_home">
              <div className="squereTwoCard_home">
                <div className="squereTwoCard_Top" style={{backgroundImage: `url(${projectTopBg[selectedItem]})`}}>
                  <div className="back_button" onClick={() => handleUnCheckItem()}>
                  </div>
                  <img src={projecticon[selectedItem]} alt="icon" className="card_icon"/>
                  <img src={projectLink[selectedItem]} alt="projectImg" className="play_btn" onClick={() => goToLink(projectNames[selectedItem])}/>
                </div>
                <div className="squereTwoCard_mid">
                  <p className="project_top_text">
                    {selectedItem !== null && projectNames[selectedItem]}
                  </p>
                  <p className="project_mid_text">
                    {projectCardDescription[selectedItem]}
                  </p>
                </div>
                <div className="squereTwoCard_bottom" style={{backgroundImage: `url(${projectFramework[selectedItem]})`}}></div>
                <div className="squereTwoCard_pic" style={{backgroundImage: `url(${projectSS[selectedItem]})`}}>
                </div>
              </div>
            </div>
          </div>
          <div className="p5 hidden" id="contact">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', height: '50vh', width: '90vw', paddingBottom: '100px'}}>
          <form method="post" name='contact-form' action='https://script.google.com/macros/s/AKfycbybijL1Eb-9ehop2oOFkANOX1qdU_6F5ugxvi9ldji0lwJZTcAWjTG8kGCkDJnagfCB/exec' style={{ backgroundColor: '#212121', height: '35vh', width: '40vw', borderTopRightRadius: '0px', borderBottomRightRadius: '0px', borderTopLeftRadius: '15px', borderBottomLeftRadius: '15px', overflow: 'hidden', padding: '10px', boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.5)'}}>
          <div style={{ marginBottom: '10px',marginTop: '10px', display: 'flex', justifyContent: 'space-between', backgroundColor: '#212121' }}>
            <div style={{ flex: 1, marginRight: '10px' }}>
              <label style={{ color: 'white' }}>Name:</label>
              <input type="text" name="name" style={{ width: '90%', padding: '8px', borderRadius: '3px', border: '1px solid #212121', backgroundColor: '#333', color: 'white' }} />
            </div>
            <div style={{ flex: 1 }}>
              <label style={{ color: 'white' }}>Email:</label>
              <input type="text" name="email" style={{ width: '90%', padding: '8px', borderRadius: '3px', border: '1px solid #212121', backgroundColor: '#333', color: 'white' }} />
            </div>
          </div>
          <p style={{ marginBottom: '10px' }}>
            <label style={{ color: 'white' }}>Subject:</label>
            <input type="text" name="subject" style={{ width: '95%', padding: '8px', borderRadius: '3px', border: '1px solid #212121', backgroundColor: '#333', color: 'white' }} />
          </p>
          <p style={{ marginBottom: '10px' }}>
            <label style={{ color: 'white' }}>Message:</label>
            <textarea name="message" rows="4" style={{ width: '95%', padding: '8px', borderRadius: '3px', border: '1px solid #212121', backgroundColor: '#333', color: 'white' }}></textarea>
          </p>
          <button type="submit" style={{ backgroundColor: '#4070F4', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Send Message</button>
        </form>
            <div style={{ display: 'flex',flexDirection: 'column', backgroundColor: '#4070F4', width: '20vw', height: '80%', borderRadius: '15px', justifyContent: 'center', overflow: 'hidden', boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.5)'}}>
              <p style={{ color: 'white', textAlign: 'center', fontSize: '32px' }}> Contact me</p>
              <img src={mePicture} alt="meg" style={{height: '60%'}}/>
            </div>
          </div>
          </div>
          <div className="bottomBorder">
            <div className="bottomBorderBox">
              <p className="bottom_Text" onClick={() => scrollToElement('about')}>About me</p>
              <p className="bottom_Text" onClick={() => scrollToElement('work')}>Work experience</p>
            </div>
            <div className="bottomBorderBox">
              <p className="bottom_Text" onClick={() => scrollToElement('education')}>Education</p>
              <p className="bottom_Text" onClick={() => scrollToElement('projects')}>Projects</p>
            </div>
            <div className="bottomBorder3">
              <p className="bottom_Text" onClick={() => scrollToElement('contact')}>Markus Liland - 2024</p>
              <img src={bitmoji} alt="projectImg" className="bitmoji" />
            </div>
          </div>
        </div>
      )}
    </div>
    
  );
}

export default Home;